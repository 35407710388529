import React from 'react'
import clsx from 'clsx'
import Icon from './Icon'

const Spinner = ({ className = '', size, color, ...props }) => (
    <Icon
        name="spinner"
        className={clsx('animate-spin-slow', className)}
        size={size}
        color={color}
        {...props}
    />
)

export default Spinner
